:root {
	// Font family ***
	--kumbh: "Kumbh Sans", sans-serif;

	// Colors ***
	--primary-color: #3c72fc;
	--primary10: rgba(60, 114, 252, 0.1);
	--gradient-bg: linear-gradient(90deg, #3c72fc -10.59%, #00060c 300.59%);
	--secondary-color: #0f0d1d;
	--main-bg: #ffffff;
	--sub-bg: #f3f7fb;
	--heading-color: #0f0d1d;
	--paragraph: #585858;
	--span: #585858;
	--border: #e3e3e3;
	--white: #ffffff;
	--black: #000000;

	// border ***
	--border-1px: 1px solid #eaecf0;
	--border-2px: 2px solid #eaecf0;

	// Transitions ***
	--transition: all 0.3s ease-in-out;

	// Box shadow ***
	--shadow: 0px 4px 25px 0px #0000000f;
}

// Dark mood collor area start here ***
:root[data-theme="dark"] {
	--primary-color: #3c72fc;
	--primary10: rgba(60, 114, 252, 0.1);
	--gradient-bg: linear-gradient(90deg, #3c72fc -10.59%, #00060c 300.59%);
	--secondary-color: #0f0d1d;
	--main-bg: #151327;
	--sub-bg: #16142c;
	--heading-color: #fff;
	--paragraph: rgba(255, 255, 255, 0.8);
	--span: rgba(255, 255, 255, 0.8);
	--border: #e3e3e3;
	--white: #ffffff;
	--black: #000000;
}
// Dark mood collor area end here ***

// Overight the Dark mood collor area stat here ***
:root[data-theme="dark"] .light-area {
	--primary-color: #3c72fc;
	--primary10: rgba(60, 114, 252, 0.1);
	--gradient-bg: linear-gradient(90deg, #3c72fc -10.59%, #00060c 300.59%);
	--secondary-color: #0f0d1d;
	--main-bg: #ffffff;
	--sub-bg: #f3f7fb;
	--heading-color: #0f0d1d;
	--paragraph: #585858;
	--span: #585858;
	--border: #e3e3e3;
	--white: #ffffff;
	--black: #000000;
}
// Overight the Dark mood collor area end here ***

// Logo area start here ***
.logo {
	display: block;
	width: 196px;
	img {
		width: 100%;
	}
}
// Logo area end here ***

// Top header area start here ***
.header-top {
	padding: 16px 0;
	overflow: hidden;
	background-color: var(--heading-color);
	.header-top-wrp {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.info {
			color: var(--white);
			span {
				color: var(--white);
			}
			a {
				transition: var(--transition);
				color: var(--white);
				font-size: 15px;
				&:hover {
					color: var(--primary-color);
				}
			}
			i {
				color: var(--primary-color);
				padding-right: 5px;
			}
			li {
				float: left;
				line-height: 0;
			}
		}
		.link-info {
			position: relative;
			z-index: 2;
			li {
				float: left;
				line-height: 0;
				a {
					width: 37px;
					color: var(--white);
					text-align: center;
					&:hover {
						color: var(--primary-color);
					}
				}
			}
		}
	}
}
// Top header area end here ***

// Header area start here ***
.header {
	&-area {
		position: relative;
		&::after {
			position: absolute;
			top: 0;
			left: 0;
			width: 30%;
			background: linear-gradient(270deg, #3c72fc 6.32%, #00060c 216.42%);
			height: 100%;
			content: "";
			z-index: -1;
			clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
			@include breakpoint(max-xl) {
				width: 25%;
			}
			@include breakpoint(max-lg) {
				width: 30%;
			}
			@include breakpoint(max-md) {
				width: 40%;
			}
			@include breakpoint(max-sm) {
				width: 50%;
			}
			@include breakpoint(max-xxsmall) {
				width: 70%;
			}
		}
		&.menu-fixed {
			position: fixed;
			width: 100%;
			top: 0;
			background-color: var(--white);
			z-index: 999;
			box-shadow: var(--shadow);
			.mega-menu {
				top: 105px !important;
			}
		}
	}
	&__container {
		max-width: 1350px;
		padding: 0 15px;
	}
	&__main {
		@include justify-wrp;
		.main-menu {
			ul {
				@include flex;
				gap: 35px;
				li {
					position: relative;
					&.has-megamenu {
						position: static;
						&:hover {
							.sub-menu {
								opacity: 1;
								visibility: visible;
								transform: translateX(-50%) translateY(0px);
								// display: block;
							}
						}
					}
					a {
						font-weight: 600;
						padding: 40px 0;
						i {
							font-size: 12px;
							transition: var(--transition);
						}
					}
					.sub-menu {
						position: absolute;
						left: 0;
						top: 105px;
						z-index: 99;
						flex-direction: column;
						gap: 0;
						width: 250px;
						border: var(--border-1px);
						box-shadow: var(--shadow);
						background-color: var(--white);
						transition: var(--transition);
						opacity: 0;
						visibility: hidden;
						transform: translateY(10px);
						// display: none;
						&.mega-menu {
							max-width: 1170px;
							width: 100%;
							left: 50%;
							top: 105px;
							transform: translateX(-50%) translateY(10px);
							padding: 30px;
							li {
								@include flex;
								gap: 20px;
								justify-content: space-around;
							}
						}
						&.menu-image {
							.image {
								position: relative;
								@extend .overlay;
								&::before {
									background-color: rgba(0, 0, 0, 0.5);
								}
								h6 {
									position: absolute;
									bottom: 10px;
									left: 50%;
									transform: translateX(-50%);
									font-size: 18px;
								}
								.btn__group {
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
									width: 60%;
									display: flex;
									justify-content: center;
									flex-direction: column;
									opacity: 0;
									visibility: hidden;
									transition: var(--transition);
									margin-top: 20px;
									.btn-one {
										color: var(--white) !important;
									}
								}
								&:hover {
									.btn__group {
										opacity: 1;
										visibility: visible;
										margin-top: 0;
									}
								}
							}
						}
						li {
							width: 100%;
							&:not(:last-child) {
								border-bottom: 1px solid var(--border);
							}
							a {
								display: block;
								padding: 10px 20px;
								color: var(--heading-color) !important;
								&:hover {
									padding-left: 25px;
									color: var(--primary-color) !important;
								}
							}
						}
					}
					&:hover {
						.sub-menu {
							opacity: 1;
							visibility: visible;
							transform: translateY(0px);
							// display: block;
						}
						&:hover {
							a {
								color: var(--primary-color);
							}
							i {
								transform: rotate(-180deg);
								color: var(--primary-color);
							}
						}
					}
				}
			}
		}
		@include breakpoint(max-lg) {
			padding: 15px 0;
		}
		.bars {
			i {
				font-size: 18px;
				cursor: pointer;
				display: inline-block;
				color: var(--paragraph);
				padding: 10px;
				border-radius: 10px;
				border: var(--border-1px);
			}
		}
	}
	&-two {
		&-area {
			&:after {
				display: none;
			}
			position: fixed;
			width: 100%;
			top: 0;
			z-index: 999;
			border-bottom: 1px solid rgba(255, 255, 255, 0.3);
			&.menu-fixed {
				background: linear-gradient(
					90deg,
					#0f0d1d -76.72%,
					#3c72fc 191.51%
				);
				border-bottom: none;
			}
			.header__main {
				.main-menu {
					ul {
						li {
							a {
								color: var(--white);
							}
						}
					}
				}
				.bars {
					i {
						color: var(--white);
					}
				}
			}
		}
	}
	&-three {
		&-area {
			&:after {
				display: none;
			}
			position: fixed;
			width: 100%;
			top: 0;
			z-index: 999;
			border-bottom: 1px solid rgba(255, 255, 255, 0.3);
			&.menu-fixed {
				background: linear-gradient(
					90deg,
					#0f0d1d -76.72%,
					#3c72fc 191.51%
				);
				border-bottom: none;
			}
			.header__main {
				.main-menu {
					ul {
						li {
							a {
								color: var(--white);
								position: relative;
								&::after {
									position: absolute;
									content: "";
									background: linear-gradient(
										0.49deg,
										#3c72fc -126.52%,
										rgba(60, 114, 252, 0) 92.35%
									);
									width: 85px;
									height: 100%;
									bottom: 0;
									left: -20px;
									opacity: 0;
									visibility: hidden;
								}
								&.search-trigger {
									&::after {
										display: none;
									}
								}
							}
							&:hover {
								a {
									&::after {
										opacity: 1;
										visibility: visible;
									}
									color: var(--white);
								}
							}
						}
					}
					.sub-menu {
						left: -20px;
						a {
							&::after {
								display: none;
							}
						}
					}
				}
				.bars {
					i {
						color: var(--white);
					}
				}
			}
		}
	}
}
// Header area end here ***
.mean-nav {
	.mega-menu {
		.image {
			padding: 20px;
			border: var(--border-1px);
			border-radius: 8px;
			margin-bottom: 20px;
			position: relative;
			h6 {
				margin-top: 20px;
			}
		}
	}
	.btn__group {
		@include center;
		a {
			text-align: center !important;
			width: 100% !important;
		}
	}
}

// Sidebar area start here ***
.sidebar-area {
	position: fixed;
	top: 0;
	right: 0px;
	width: 400px;
	height: 100%;
	background-color: var(--heading-color);
	padding: 40px;
	padding-top: 30px;
	z-index: 9999;
	transition: var(--transition);
	overflow: hidden;
	overflow-y: auto;
	@include breakpoint(max-sm) {
		width: 350px;
	}
	p {
		color: var(--white);
	}
	.info {
		border-top: 1px solid rgba(255, 255, 255, 0.25);
		li {
			font-size: 15px;
			i {
				margin-right: 8px;
			}
			a {
				color: var(--white);
			}
		}
	}
	.sidebar__overlay {
		position: fixed;
		right: 0;
		top: 0;
		background-color: rgba(0, 0, 0, 0.5);
		width: 100%;
		height: 100%;
		z-index: -1;
	}
	button {
		position: absolute;
		right: 30px;
		top: 30px;
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		font-size: 20px;
		background-color: var(--primary-color);
		border-radius: 50%;
		transition: var(--transition);
		&:hover {
			transform: rotate(90deg);
		}
	}
	.social-icon {
		a {
			@include radius(40px, 40px, 40px);
			border: 1px solid rgba(255, 255, 255, 0.25);
			color: var(--primary-color);
			margin-right: 10px;
			&:hover {
				background-color: var(--primary-color);
				color: var(--heading-color);
				border: 1px solid var(--primary-color);
			}
		}
	}
	&.sidebar__hide {
		visibility: hidden;
		opacity: 0;
		right: -30px;
	}
	.sidebar__search {
		position: relative;
		input {
			width: 100%;
			padding: 8px 20px;
			padding-right: 40px;
		}
		i {
			position: absolute;
			top: 16px;
			right: 16px;
			cursor: pointer;
			color: var(--heading-color);
		}
	}
}
// Sidebar area end here ***

// Mobile menu area start here ***
.mobile-menu {
	.meanmenu-reveal {
		display: none !important;
	}
	&.mean-container .mean-nav > ul {
		width: 100%;
		display: block !important;
	}
	ul {
		li {
			a {
				i {
					display: none;
				}
			}
		}
	}
}
// Mobile menu area end here ***

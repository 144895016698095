/*-----------------------------------------------------------------

Template Name: Gratech - IT Service HTML Template
Author:  Gramentheme
Author URI: https://themeforest.net/user/gramentheme/portfolio
Developer: Kawser Ahmed Roni
Version: 1.0.0
Description: Gratech - IT Service HTML5 Template

-------------------------------------------------------------------
CSS TABLE OF CONTENTS
-------------------------------------------------------------------

01. abstracts
    1.01 --> mixins
    1.02 --> variable

02. base
    2.01 --> typography
    2.02 --> animation
    2.03 --> responsive

03. components
    3.01 --> buttons
    3.02 --> progress

04. layout
    4.01 --> header
    4.02 --> banner
    4.03 --> section
    4.04 --> footer

------------------------------------------------------------------*/

// Google fonts ***
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");

// Abstracts styles ***
@import "./sass/_abstracts/_mixins";
@import "./sass/_abstracts/_variables";

// Typography styles***
@import "./sass/_base/_typography";

// Component styles ***
@import "./sass/_components/_buttons";
@import "./sass/_components/_progress";

// Layout styles ***
@import "./sass/_layout/_header";
@import "./sass/_layout/_banner";
@import "./sass/_layout/_section";
@import "./sass/_layout/_footer";

// Animation styles***
@import "./sass/_base/_animation";


@font-face {
  font-family: "v4tech-fonts";
  src: url("//assets/webfonts/fa-brands-400.ttf") format('woff2'),
  url("//assets/webfonts/fa-brands-400.woff2") format('woff2'),
  url("//assets/webfonts/fa-duotone-900.ttf") format('ttf'),
  url("//assets/webfonts/fa-duotone-900.woff2") format('woff2'),
  url("//assets/webfonts/fa-light-300.ttf") format('ttf'),
  url("//assets/webfonts/fa-light-300.woff2")format('woff2'),
  url("//assets/webfonts/fa-regular-400.ttf")format('ttf'),
  url("//assets/webfonts/fa-regular-400.woff2")format('woff2'),
  url("//assets/webfonts/fa-solid-900.ttf")format('ttf'),
  url("//assets/webfonts/fa-solid-900.woff2")format('woff2'),
  url("//assets/webfonts/fa-thin-100.ttf")format('ttf'),
  url("//assets/webfonts/fa-thin-100.woff2")format('woff2'),
  url("//assets/webfonts/fa-v4compatibility.ttf")format('ttf'),
  url("//assets/webfonts/fa-v4compatibility.woff2")format('woff2');
}


